var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-10 pa-10",attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"pl-0"},[_vm._v("Customer Relationship Management")]),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-tabs',{attrs:{"color":"deep-purple accent-4"}},[_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])}),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"text-center py-5"},[_c('v-card-text',[_c('p',[_vm._v(" Forward Enable Status : "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.company.forward_status ? "Active" : "Inactive"))])]),_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.company.forward_status
                          ? 'red'
                          : 'deep-purple darken-2'}},'v-btn',attrs,false),on),[_vm._v("Make "+_vm._s(_vm.company.forward_status ? "Inactive" : "Active"))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline red pb-4 white--text"},[_vm._v(" Confirmation ")]),_c('v-card-text',[_vm._v(" Are you sure you want to change forward status ? ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.onStatusChange}},[_vm._v(" I accept ")])],1)],1)],1)],1)])],1)],1)],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsWithIndex,"items-per-page":20},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"deep-purple darken-2","dark":"","elevation":"0"},on:{"click":function($event){_vm.department_Dialog = true}}},[_vm._v(" Add Department ")])],1)]},proxy:true}])})],1)],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.staff_headers,"items":_vm.staffsWithIndex,"items-per-page":20}})],1)],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.own_users_headers,"items":_vm.own_users},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"mt-2 mb-2"},[_vm._v("All Site Users")])],1)]},proxy:true},{key:"item.role",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.role)+" ")]}},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.onOpenAddUserDialog(item)}}},[_vm._v(" mdi-plus ")])]}}])})],1)],1)],2)],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.department_Dialog),callback:function ($$v) {_vm.department_Dialog=$$v},expression:"department_Dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"deep-purple darken-2","dark":""}},[_vm._v("New Department")]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","color":"deep-purple darken-2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"mb-0 font-weight-bold text-h4"},[_vm._v("Department Name")])]},proxy:true}]),model:{value:(_vm.department_name),callback:function ($$v) {_vm.department_name=$$v},expression:"department_name"}})],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"red","elevation":"0"},on:{"click":function($event){_vm.department_Dialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"deep-purple darken-2","elevation":"0"},on:{"click":function($event){$event.preventDefault();return _vm.onAddDepartment.apply(null, arguments)}}},[_vm._v("Add")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.user_add_dialog),callback:function ($$v) {_vm.user_add_dialog=$$v},expression:"user_add_dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline green pb-4 white--text"},[_vm._v(" ADD USER ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.departments,"item-text":"department_name","item-value":"id","return-object":"","label":"Select Department","outlined":""},model:{value:(_vm.selected_department),callback:function ($$v) {_vm.selected_department=$$v},expression:"selected_department"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","elevation":"0","disabled":_vm.selected_department &&
            Object.keys(_vm.selected_department).length === 0 &&
            _vm.selected_department.constructor === Object},on:{"click":_vm.onAddUser}},[_vm._v(" Add ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":"white","centered":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('p',{staticClass:"mb-0",class:[_vm.error ? 'red--text' : 'black--text']},[_vm._v(" "+_vm._s(_vm.text)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }