<template>
  <v-card class="ma-10 pa-10" fluid>
    <v-card-title class="pl-0">Customer Relationship Management</v-card-title>

    <v-card elevation="0">
      <v-tabs color="deep-purple accent-4">
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>

        <!-- Forward enable/disable option -->
        <v-tab-item>
          <v-container fluid>
            <v-card class="text-center py-5">
              <v-card-text>
                <p>
                  Forward Enable Status :
                  <span class="font-weight-bold">{{
                    company.forward_status ? "Active" : "Inactive"
                  }}</span>
                </p>
                <!-- <v-btn
                  :color="company.forward_status ? 'red' : 'secondary'"
                  elevation="2"
                  @click="onStatusChange"
                  >Make
                  {{ company.forward_status ? "Inactive" : "Active" }}</v-btn
                > -->

                <div class="text-center">
                  <v-dialog v-model="dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="
                          company.forward_status
                            ? 'red'
                            : 'deep-purple darken-2'
                        "
                        v-bind="attrs"
                        v-on="on"
                        >Make
                        {{
                          company.forward_status ? "Inactive" : "Active"
                        }}</v-btn
                      >
                    </template>

                    <v-card>
                      <v-card-title class="headline red pb-4 white--text">
                        Confirmation
                      </v-card-title>

                      <v-card-text>
                        Are you sure you want to change forward status ?
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="onStatusChange">
                          I accept
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-card-text>
            </v-card>
          </v-container>
        </v-tab-item>

        <!-- Add departments for company -->
        <v-tab-item>
          <v-container fluid>
            <v-data-table
              :headers="headers"
              :items="itemsWithIndex"
              :items-per-page="20"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="deep-purple darken-2"
                    dark
                    class="mb-2"
                    elevation="0"
                    @click="department_Dialog = true"
                  >
                    Add Department
                  </v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-container>
        </v-tab-item>

        <!-- Staffs for company -->
        <v-tab-item>
          <v-container fluid>
            <v-data-table
              :headers="staff_headers"
              :items="staffsWithIndex"
              :items-per-page="20"
              class="elevation-1"
            >
            </v-data-table>
          </v-container>
        </v-tab-item>

        <!-- Staffs for company -->
        <v-tab-item>
          <v-container fluid>
            <v-data-table
              :headers="own_users_headers"
              :items="own_users"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar elevation="0">
                  <v-toolbar-title class="mt-2 mb-2"
                    >All Site Users</v-toolbar-title
                  >
                </v-toolbar>
              </template>

              <template v-slot:item.role="{ item }">
                {{ item.role }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon small @click="onOpenAddUserDialog(item)">
                  mdi-plus
                </v-icon>
              </template>
            </v-data-table>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <!-- Add a department -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="department_Dialog"
    >
      <v-card>
        <v-toolbar color="deep-purple darken-2" dark>New Department</v-toolbar>
        <v-card-text class="pb-0">
          <v-col cols="12">
            <v-text-field
              outlined
              v-model="department_name"
              color="deep-purple darken-2"
            >
              <template v-slot:label>
                <p class="mb-0 font-weight-bold text-h4">Department Name</p>
              </template>
            </v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="red" @click="department_Dialog = false" elevation="0"
            >Close</v-btn
          >
          <v-btn
            color="deep-purple darken-2"
            elevation="0"
            @click.prevent="onAddDepartment"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="user_add_dialog" width="500">
      <v-card>
        <v-card-title class="headline green pb-4 white--text">
          ADD USER
        </v-card-title>
        <v-card-text>
          <v-select
            :items="departments"
            item-text="department_name"
            item-value="id"
            v-model="selected_department"
            return-object
            label="Select Department"
            outlined
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            @click="onAddUser"
            elevation="0"
            :disabled="
              selected_department &&
              Object.keys(selected_department).length === 0 &&
              selected_department.constructor === Object
            "
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="white" centered>
      <p :class="[error ? 'red--text' : 'black--text']" class="mb-0">
        {{ text }}
      </p>

      <!-- <template v-slot:action="{ attrs }">
        <v-btn :color="btnColor" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template> -->
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>


<script>
export default {
  data: () => ({
    // items: ["Manage", "Add user", "Add departments"],
    items: ["API Management", "Departments", "CRM Staffs", "ADD Staff"],
    dialog: false,
    snackbar: false,
    text: "",
    error: false,
    company: {},
    headers: [
      { text: "S/N", value: "index" },
      { text: "Department Name", value: "department_name" },
    ],
    staff_headers: [
      { text: "S/N", value: "index" },
      { text: "Name", value: "user_name" },
      { text: "Email", value: "user_email" },
      { text: "Phone", value: "user_phone_number" },
      { text: "Role", value: "user_role" },
    ],
    departments: [],
    staffs: [],
    department_Dialog: false,
    selected_department: {},
    selected_user: {},
    department_name: "",
    own_users_headers: [
      { text: "Full Name", value: "username" },
      { text: "Email", value: "email" },
      { text: "Contact Number", value: "phone_number" },
      { text: "Role", value: "role" },
      { text: "Actions", value: "actions" },
    ],
    own_users: [],
    user_add_dialog: false,

    crm_server_url : 'https://server.support24.com.bd/'
  }),

  computed: {
    itemsWithIndex() {
      return this.departments.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },

    staffsWithIndex() {
      return this.staffs.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },

  methods: {
    initialize() {
      // Get forward enable/disable information from CRM
      axios
        .get(`${this.crm_server_url}site/forward-status`)
        .then((response) => {
          if (response.data.success) {
            this.company = response.data.company;
            // GET all departments
            this.getAllDepartments();
            // GET all users
            this.getAllUsers();
          }
        })
        .catch((err) => {
          // Handle error
        });
    },

    // GET all users of own site
    getOwnStaffs() {
      axios.get("user/show_crm_available_users/").then((response) => {
        this.own_users = response.data.data;
      });
    },

    // Get all departments of the company
    getAllDepartments() {
      axios
        .get(
          `${this.crm_server_url}site/departments/${this.company.company_id}`
        )
        .then((response) => {
          if (response.data.success && response.data.departments) {
            this.departments = response.data.departments;
          }
        })
        .catch((err) => {
          // Handle error
        });
    },

    // Get all Staff of the company
    getAllUsers() {
      axios
        .get(`${this.crm_server_url}site/staff/${this.company.company_id}`)
        .then((response) => {
          if (response.data.success && response.data.all_staff_list.length) {
            this.staffs = response.data.all_staff_list;
          }
        })
        .catch((err) => {
          // Handle error
          // console.log("My error", err.response.data.message);
        });
    },

    // Change forward status
    onStatusChange() {
      if (this.company.forward_status) {
        axios
          .get(
            `${this.crm_server_url}site/change-status/${this.company.company_id}/0`
          )
          .then((response) => {
            if (response.data.success) {
              this.company.forward_status = false;
              this.createSnackbar(response.data.message);
            } else {
              this.createSnackbar(response.data.message);
            }
          })
          .catch((err) => {
            this.createSnackbar(response.data.message);
          });
      } else {
        axios
          .get(
            `${this.crm_server_url}site/change-status/${this.company.company_id}/1`
          )
          .then((response) => {
            if (response.data.success) {
              this.company.forward_status = true;
              this.createSnackbar(response.data.message);
            } else {
              this.createSnackbar(response.data.message);
            }
          })
          .catch((err) => {
            this.createSnackbar(err.response.data.message);
          });
      }
    },

    // Add department
    onAddDepartment() {
      if (this.department_name.trim()) {
        let payload = {
          company_id: this.company.company_id,
          department_name: this.department_name.trim(),
        };

        axios
          .post(`${this.crm_server_url}site/departments`, payload)
          .then((response) => {
            if (response.data.success) {
              this.departments.unshift(response.data.new_department);
              this.department_Dialog = false;
              this.createSnackbar(response.data.message);
            }
          })
          .catch((err) => {
            this.department_Dialog = false;
            this.createSnackbar(err.response.data.message);
          });
      } else {
        this.department_name = "";
        this.createSnackbar("Please provide a valid department name.");
      }
    },

    // Open add user dialog
    onOpenAddUserDialog(item) {
      this.selected_user = item;
      this.user_add_dialog = true;
    },

    // Add user to crm
    onAddUser() {
      let payload = {
        company_id: this.selected_department.company_id,
        department_id: this.selected_department.id,
        user_name: this.selected_user.username,
        user_email: this.selected_user.email,
        user_phone_number: this.selected_user.phone_number,
        site_user_id: this.selected_user.id,
        user_role: this.selected_user.role,
      };

      axios
        .post(`${crm_server_url}site/staff`, payload)
        .then((response) => {
          if (response.data.success) {
            this.user_add_dialog = false;
            this.selected_user = {};
            this.selected_department = {};
            this.createSnackbar(response.data.message);

            // Change status of the user at own site
            axios
              .get(`user/change_crm_user_status/${payload.site_user_id}/`)
              .then((response) => {
                setTimeout(() => {
                  this.$router.go(this.$router.currentRoute);
                }, 1000);
              })
              .catch((err) => {
                // handle error
              });
          }
        })
        .catch((err) => {
          this.createSnackbar(err.response.data.message);
        });
    },

    // Create snackbar
    createSnackbar(text) {
      this.text = text;
      this.dialog = false;
      this.snackbar = true;
    },
  },
  mounted() {
    this.initialize();
    this.getOwnStaffs();
  },
};
</script>

<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>
